<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="employeeData"
      item-key="id"
      sort-by="name"
      :search="buscar"
      :header-props="headerProps"
      :footer-props="footerProps"
      :loading="loadingEmployees"
      loading-text="Cargando Empleados..."
      no-data-text="No hay registros para mostrar."
      no-results-text="No se encontraron registros en la búsqueda"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            v-model="buscar"
            append-icon="mdi-magnify"
            label="Buscar por empleado / dni / email / etc."
            outlined
            dense
          ></v-text-field>
          <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
          <v-spacer></v-spacer>
          <v-tooltip
            left
            color="primary"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                elevation="10"
                small
                fab
                color="primary"
                dark
                class="mx-2"
                v-bind="attrs"
                v-on="on"
                @click="registerEmployee(0)"
              >
                <v-icon>
                  {{ icons.mdiAccountPlus }}
                </v-icon>
              </v-btn>
            </template>
            <span>Agregar Empleado</span>
          </v-tooltip>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <!-- <v-icon
        size="18"
        class="me-3"
      >
        {{ icons.mdiPencil }}
      </v-icon> -->

        <v-tooltip
          bottom
          color="primary"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              size="18"
              class="me-3"
              v-bind="attrs"
              v-on="on"
              @click="EditEmployee(item.personID)"
            >
              {{ icons.mdiPencil }}
            </v-icon>
          </template>
          <span>Editar Franco</span>
        </v-tooltip>
        <v-tooltip
          bottom
          color="primary"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="error"
              size="18"
              v-bind="attrs"
              class="me-3"
              v-on="on"
              @click="deleteItem(item)"
            >
              {{ icons.mdiDeleteEmpty }}
            </v-icon>
          </template>
          <span>Eliminar Empleado</span>
        </v-tooltip>

        <v-tooltip
          bottom
          color="primary"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="success"
              size="18"
              class="me-3"
              v-bind="attrs"
              v-on="on"
              @click="AssignClients(item.personID)"
            >
              {{ icons.mdiFaceManShimmer }}
            </v-icon>
          </template>
          <span>Asignar Clientes</span>
        </v-tooltip>
        <v-tooltip
          bottom
          color="info"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="info"
              size="18"
              class="me-3"
              v-bind="attrs"
              v-on="on"
              @click="searchEmployee(item.dni)"
            >
              {{ icons.mdiAccountCard   }}
            </v-icon>
          </template>
          <span>Generar Credencial</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-dialog
      v-model="dialogDelete"
      max-width="800px"
    >
      <v-card>
        <v-card-title
          v-if="empleadoAEliminar"
          class="text-h5"
        >
          ¿Está seguro que desea eliminar el empleado {{ empleadoAEliminar.name + " " + empleadoAEliminar.surname }}?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeDelete"
          >
            Cancel
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="deleteItemConfirm"
          >
            OK
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {
  mdiPencil, mdiDeleteEmpty, mdiAccountPlus, mdiEraser, mdiFaceWoman, mdiFaceMan, mdiFaceManShimmer, mdiAccountCard ,
} from '@mdi/js'
import axios from 'axios'

export default {

  data: () => ({
    loadingEmployees: false,
    dialogDelete: false,
    icons: {
      mdiPencil,
      mdiDeleteEmpty,
      mdiAccountPlus,
      mdiEraser,
      mdiFaceWoman,
      mdiFaceMan,
      mdiFaceManShimmer,
      mdiAccountCard ,
    },
    headers: [
      {
        text: 'Id',
        align: 'start',
        sortable: true,
        value: 'personID',
      },
      { text: 'DNI', value: 'dni' },
      // eslint-disable-next-line no-useless-concat
      { text: 'Empleado', value: 'name' },

      // { text: 'Sexo', value: 'sex' },
      { text: 'Telefono', value: 'phone' },
      { text: 'Cuenta', value: 'userAccount' },
      { text: 'Rol', value: 'rol' },
      { text: 'Acciones', value: 'actions', sortable: false },
    ],
    headerProps: {
      sortByText: 'Ordenar por',
    },
    footerProps: {
      itemsPerPageText: 'Registros por página',
      pageText: '{0}-{1} de {2}',
      itemsPerPageOptions: [5, 10, 20, 50, 100, { text: 'Todos', value: -1 }],
    },
    employeeData: [],
    buscar: '',
    empleadoAEliminar: null,
  }),

  mounted() {
    this.getListtEmployee()
  },
  methods: {
    registerEmployee(tipoid) {
      // this.$router.push('/registeremployee')
      this.$router.push({ name: 'registeremployee', params: { id: tipoid } })
    },
    EditEmployee(tipoid) {
      // this.$router.push('/registeremployee')
      this.$router.push({ name: 'registeremployee', params: { id: tipoid } })
    },
    AssignClients(tipoid) {
      this.$router.push({ name: 'clientsassigned', params: { id: tipoid } })
    },
    deleteItem(item) {
      this.empleadoAEliminar = item
      this.dialogDelete = true
    },
    deleteItemConfirm() {
      this.EliminarEmployeeConfirmado()
      this.closeDelete()
    },
    closeDelete() {
      this.dialogDelete = false
      this.empleadoAEliminar = null
    },
    async getListtEmployee() {
      this.loadingEmployees = true
      this.url = `${this.$store.getters.urlBase}/api/Employee`
      const options = {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
        },
        url: this.url,
      }
      const respuesta = await axios(this.url, options)
      if (respuesta.status === 200) {
      // console.log(respuesta.data);
        this.employeeData = respuesta.data
        this.loadingEmployees = false

      // this.getListarDiasMantenimiento();
      } else {
      // this.colorSnack = "red";
      // this.mensajeSnack = respuesta.data;
      // this.Showsnackbar = true;
        this.loadingEmployees = false
      }
    },
    async searchEmployee(dni) {
      this.url = `${this.$store.getters.urlBase}/api/Employee/employeeByDNI`

      const options = {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
        },
        params: { dni: parseInt(dni) },
        url: this.url,
      }
      const respuesta = await axios(this.url, options)

      if (respuesta.status === 200) {
        this.$router.push({ name: 'reportcredential', params: { dni: `${respuesta.data.dni}`, rol: respuesta.data.rol, employee: `${respuesta.data.surname} ${respuesta.data.name}` } })
      } else {

        // this.colorSnack = "red";
        // this.mensajeSnack = respuesta.data;
        // this.Showsnackbar = true;
      }
    },
    async EliminarEmployeeConfirmado() {
      this.url = `${this.$store.getters.urlBase}/api/Employee/delete`

      const params = {}
      params.personId = parseInt(this.empleadoAEliminar.personID)

      //  params.loggedPersonId = parseInt(this.$store.getters.usuario.user_personid)
      const options = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${this.$store.getters.usuario.token.token}`,
          'content-type': 'application/json',
        },
        data: params,
        url: this.url,
      }
      const respuesta = await axios(this.url, options)

      // this.url = `${this.$store.getters.urlBase}/api/Client`
      // const options = {
      //   method: 'DELETE',
      //   url: this.url,
      // }
      // const respuesta = await axios(this.url, options)
      if (respuesta.status === 200) {
        this.$store.dispatch('showSnack', {
          text: 'Empleado eliminado',
          color: 'success',
          timeout: 2500,
        })
      } else {
        if (respuesta.response.data) {
          this.mensajeSnack = `Error: ${respuesta.response.data}`
        } else if (respuesta.message) {
          this.mensajeSnack = `Error: ${respuesta.message}`
        } else {
          this.mensajeSnack = 'Error de Conexion'
        }
        this.$store.dispatch('showSnack', {
          text: this.mensajeSnack,
          color: 'error',
          timeout: 2500,
        })
      }
      this.clienteAEliminar = null
      this.getListtEmployee()
    },
  },
}
</script>
