var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.employeeData,"item-key":"id","sort-by":"name","search":_vm.buscar,"header-props":_vm.headerProps,"footer-props":_vm.footerProps,"loading":_vm.loadingEmployees,"loading-text":"Cargando Empleados...","no-data-text":"No hay registros para mostrar.","no-results-text":"No se encontraron registros en la búsqueda"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar por empleado / dni / email / etc.","outlined":"","dense":""},model:{value:(_vm.buscar),callback:function ($$v) {_vm.buscar=$$v},expression:"buscar"}}),_c('v-spacer'),_c('v-tooltip',{attrs:{"left":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"elevation":"10","small":"","fab":"","color":"primary","dark":""},on:{"click":function($event){return _vm.registerEmployee(0)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiAccountPlus)+" ")])],1)]}}])},[_c('span',[_vm._v("Agregar Empleado")])])],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"me-3",attrs:{"size":"18"},on:{"click":function($event){return _vm.EditEmployee(item.personID)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")])]}}],null,true)},[_c('span',[_vm._v("Editar Franco")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"me-3",attrs:{"color":"error","size":"18"},on:{"click":function($event){return _vm.deleteItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteEmpty)+" ")])]}}],null,true)},[_c('span',[_vm._v("Eliminar Empleado")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"me-3",attrs:{"color":"success","size":"18"},on:{"click":function($event){return _vm.AssignClients(item.personID)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiFaceManShimmer)+" ")])]}}],null,true)},[_c('span',[_vm._v("Asignar Clientes")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"info"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"me-3",attrs:{"color":"info","size":"18"},on:{"click":function($event){return _vm.searchEmployee(item.dni)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiAccountCard)+" ")])]}}],null,true)},[_c('span',[_vm._v("Generar Credencial")])])]}}])}),_c('v-dialog',{attrs:{"max-width":"800px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[(_vm.empleadoAEliminar)?_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" ¿Está seguro que desea eliminar el empleado "+_vm._s(_vm.empleadoAEliminar.name + " " + _vm.empleadoAEliminar.surname)+"? ")]):_vm._e(),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v(" OK ")]),_c('v-spacer')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }